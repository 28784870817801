import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../firebaseConfig";

class FirebaseAppProvider {
    constructor() {
        this._app = initializeApp(firebaseConfig);
    }

    provide() {
        return this._app;
    }
}

export const firebaseAppProvider = new FirebaseAppProvider();