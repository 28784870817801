import { useEffect } from "react";
import { initializer } from "./services/Initializer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Theme } from "./Theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LandingPage } from "./pages/LandingPage";

export const App = () => {
    useEffect(() => {
        initializer.initialize();
    }, []);

    return (
        <ThemeProvider theme={Theme}>
            <CssBaseline />
            <Router>
                <Switch>
                    <Route path="/">
                        <LandingPage />
                    </Route>
                </Switch>
            </Router>
        </ThemeProvider>
    );
}