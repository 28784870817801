import { createMuiTheme } from "@mui/material";

export const Theme = createMuiTheme({
    palette: {
        mode: 'dark',
        background: {
            default: "#252525",
        },
        primary: {
            main: '#6d6d6d',
            light: '#4d4d4d',
            dark: '#000000',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#ffd877',
            main: '#d4a748',
            dark: '#9f7817',
            contrastText: '#000000',
        },
        divider: '#424242',
    },
    typography: {
        h1: {
            fontWeight: "bold"
        },
        h2: {
            fontWeight: "bold"
        },
        h3: {
            fontWeight: "bold"
        },
        h4: {
            fontWeight: "bold"
        }
    }
});