import { Grid, Typography, Card, CardContent, Button } from "@mui/material";
import { useStyles } from "./useStyles";
import ReactAudioPlayer from "react-audio-player";

export const MusicCard = (props) => {
    const styles = useStyles();

    return (
        <Card className={styles.card}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid container item spacing={2} justifyContent="center">
                        <Grid item>
                            <img alt="Cover" width={130} src={props.cover} />
                        </Grid>
                        <Grid item>
                            <Grid item>
                                <Typography variant="h6" gutterBottom>
                                    {props.name}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button href={props.link} color="secondary" variant="outlined">
                                    Open in music apps
                                </Button>
                            </Grid>
                            <Grid item>
                                <br />
                            </Grid>
                            <Grid item>
                                <ReactAudioPlayer
                                    src={props.audioSrc}
                                    controls
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}